import {
  Button,
  Divider,
  Group,
  Select,
  SelectProps,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { size } from 'lodash/fp';
import React from 'react';

import {
  useSendSupportEmail,
  UseSendSupportEmailParams,
} from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ContactSupportModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';
import { StateType } from '@portals/types';

import { useCloud2CloudFormData } from '../../add-device-modal.hooks';
import { FormComponentCommonProps } from '../../add-device-modal.types';
import ConfigurationMissingMessage from '../../ConfigurationMissingMessage';
import { ModelField } from '../fields/ModelField';
import { VendorField } from '../fields/VendorField';

export function ZoomForm({
  selectedPartnerId,
  setSelectedPartnerId,
  handleSubmit,
  handleClose,
  isSubmitDisabled,
}: FormComponentCommonProps) {
  const {
    integration,
    fetchIntegration,
    isLoading,
    options,
    showConfigurationMissingMessage,
  } = useCloud2CloudFormData({
    integrationName: 'zoom',
    composeOptions: composeZoomRoomOptions,
  });
  const openModal = useOpenModal();
  const sendSupportEmail = useSendSupportEmail();

  function onSendSupportEmail(values: UseSendSupportEmailParams) {
    sendSupportEmail.mutate(
      {
        title: values.title,
        message: values.message,
      },
      {
        onSuccess: () => {
          openModal('ContactSupportSuccessModal');
        },
      }
    );
  }

  const form = useForm({
    initialValues: {
      zoom_room: '',
      name: '',
      model: '',
    },
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack p="xxl">
        <VendorField
          vendor={selectedPartnerId}
          setVendor={setSelectedPartnerId}
        />

        {showConfigurationMissingMessage ? (
          <ConfigurationMissingMessage
            integration={integration}
            message="Please configure the Zoom integration before claiming devices."
            linkTo="/settings/integrations/uc/zoom"
            onLinkClick={handleClose}
          />
        ) : (
          <Stack>
            {/* @todo: validate if it's needed to have a model field at all */}
            <ModelField
              partnerId={selectedPartnerId}
              fieldName="model"
              value={form.values.model}
              setFieldValue={form.setFieldValue}
            />

            <Group noWrap align="end">
              {size(options) === 0 ? (
                <span>No rooms found</span>
              ) : (
                <Select
                  data={options}
                  disabled={isLoading}
                  label="Zoom room"
                  placeholder="Select room"
                />
              )}

              <Button
                loading={isLoading}
                onClick={() => fetchIntegration({ type: 'zoom' })}
              >
                Refresh
              </Button>
            </Group>

            <TextInput
              label="Name"
              placeholder="Name"
              {...form.getInputProps('name')}
            />
          </Stack>
        )}
      </Stack>

      <Divider color="gray.2" />

      <ModalFooter position="right" p="xxl">
        <Button
          variant="subtle"
          color="blue_gray.8"
          onClick={() =>
            openModal<ContactSupportModalProps['data']>('ContactSupportModal', {
              onSubmit: onSendSupportEmail,
              title: 'Claiming a Zoom device',
            })
          }
        >
          Contact support
        </Button>

        <Button variant="default" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          type="submit"
          disabled={
            isSubmitDisabled || !form.values.zoom_room || !form.values.model
          }
          loading={isLoading}
        >
          Claim device
        </Button>
      </ModalFooter>
    </form>
  );
}

function composeZoomRoomOptions(
  integration: StateType['data']['integrations']['zoom']
): SelectProps['data'] {
  const unclaimed = integration?.extra?.unclaimed;

  if (!unclaimed) {
    return [];
  }

  return unclaimed.map((room) => ({
    value: room.id,
    label: room.name,
  }));
}
