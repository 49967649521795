import { includes } from 'lodash/fp';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSignOut } from '@portals/api/auth';
import {
  useOrganizationConfig,
  usePortalCapabilities,
} from '@portals/api/organizations';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  XyteConnectIcon,
} from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as BoxIcon } from '@portals/icons/linear/box.svg';
import { ReactComponent as DirectInbox } from '@portals/icons/linear/direct-inbox.svg';
import { ReactComponent as Logout } from '@portals/icons/linear/logout.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Settings2 } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as ShopIcon } from '@portals/icons/linear/shop.svg';
import { getAuth } from '@portals/redux';
import { StateType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import { useCanAccessConnectRouteModal } from '../../../../desktop/route-modals/Connect/ConnectToggle';

const useSidebarItems = (onClose: () => void) => {
  const location = useLocation();
  const navigate = useNavigate();
  const authentication = useSelector(getAuth);

  const onNavigate = useCallback(
    (pathToNavigate: string) => {
      onClose();
      navigate(pathToNavigate);
    },
    [navigate, onClose]
  );

  return {
    pathname: location.pathname,
    onNavigate,
    authentication,
  };
};

type SidebarItemsProps = {
  onNavigate: (path: string) => void;
  pathname: string;
};

export const DashboardSidebarItems: FC<
  SidebarItemsProps & { authentication: StateType['ui']['auth'] }
> = ({ pathname, onNavigate, authentication }) => {
  const portalCapabilities = usePortalCapabilities();
  const organizationConfig = useOrganizationConfig();
  const canAccessConnectRouteModal = useCanAccessConnectRouteModal();

  const openRouteModal = useOpenRouteModal();

  const signOut = useSignOut();

  return (
    <Container className="justify-content-between pt-5">
      {isCustomerFeatureAll(portalCapabilities?.b2c_view) ||
      isCustomerFeatureLabOnly(
        portalCapabilities?.b2c_view,
        organizationConfig?.lab
      ) ? (
        <div className="d-flex flex-column">
          {portalCapabilities?.store === true && (
            <SidebarItem
              $active={includes('/store', pathname)}
              onClick={() => onNavigate('/store')}
            >
              <ShopIcon />
              STORE
            </SidebarItem>
          )}

          {portalCapabilities?.purchased_products === true && (
            <SidebarItem
              $active={includes('/products', pathname)}
              onClick={() => onNavigate('/products')}
            >
              <BoxIcon />
              PRODUCTS
            </SidebarItem>
          )}

          <SidebarItem
            $active={includes('/settings', pathname)}
            onClick={() => onNavigate('/settings')}
            data-testid="sidebar-item-settings"
          >
            <Settings2 />
            SETTINGS
          </SidebarItem>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <SidebarCategory>CONFIGURATION</SidebarCategory>

          <SidebarItem
            $active={includes('/overview', pathname) || pathname === '/'}
            onClick={() => onNavigate('/overview')}
            data-testid="sidebar-item-claim-device"
          >
            <DirectInbox />
            CLAIM DEVICE
          </SidebarItem>

          <SidebarItem
            $active={includes('/devices', pathname)}
            onClick={() => onNavigate('/devices')}
            data-testid="sidebar-item-devices"
          >
            <MonitorMobbile />
            DEVICES
          </SidebarItem>

          {canAccessConnectRouteModal && (
            <SidebarItem
              $active={includes('/m/connect', pathname)}
              onClick={() => openRouteModal({ modalId: 'connect' })}
              data-testid="sidebar-item-connect"
            >
              <XyteConnectIcon />
              Connect
            </SidebarItem>
          )}
        </div>
      )}

      <Settings>
        <User>
          <div>{authentication.name}</div>
          <div className="text-muted">{authentication.email}</div>

          <div
            className="d-flex align-items-center mt-3"
            data-testid="logout-menu-item"
            onClick={() => signOut.mutate()}
          >
            <Logout color="white" />

            <div className="text-white ml-2 font-size-sm">SIGN OUT</div>
          </div>
        </User>
      </Settings>
    </Container>
  );
};

const SidebarItems: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { onNavigate, authentication, pathname } = useSidebarItems(onClose);

  return authentication ? (
    <DashboardSidebarItems
      onNavigate={onNavigate}
      pathname={pathname}
      authentication={authentication}
    />
  ) : null;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 60px;
`;

const SidebarCategory = styled.div`
  padding: 10px 0;
  font-size: 17px;
  color: ${getStyledThemeColor('gray600')};
`;

const SidebarItem = styled.div.attrs(() => ({
  className: 'sidebar-item',
}))<{ $active?: boolean }>`
  font-size: 20px;
  color: ${getStyledThemeColor('white')};
  width: calc(100% + 80px);
  transform: translateX(-40px);
  padding: 10px 40px;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
    width: 18px;
    height: 18px;
  }

  .badge {
    font-size: 12px;
    letter-spacing: normal;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  ${({ $active, theme }) =>
    $active &&
    `
    background-color: ${theme.color.gray100};
    color: ${theme.color.dark};
  `}

  &:active {
    background-color: ${getStyledThemeColor('gray100')};
    color: ${getStyledThemeColor('dark')};
  }
`;

const Settings = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const User = styled.div`
  flex-shrink: 0;
  color: ${getStyledThemeColor('white')};
  font-size: 16px;
  max-width: calc(100% - 70px);
`;

export default SidebarItems;
