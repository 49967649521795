import { Button, createStyles } from '@mantine/core';
import React from 'react';

import {
  useSendSupportEmail,
  UseSendSupportEmailParams,
} from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { ContactSupportModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { FormComponentCommonProps } from '../../add-device-modal.types';
import { VendorField } from '../fields/VendorField';

export function VendorForm({
  handleClose,
  selectedPartnerId,
  setSelectedPartnerId,
}: FormComponentCommonProps) {
  const { classes } = useStyles();

  const openModal = useOpenModal();
  const sendSupportEmail = useSendSupportEmail();
  function onSendSupportEmail(values: UseSendSupportEmailParams) {
    sendSupportEmail.mutate(
      {
        title: values.title,
        message: values.message,
      },
      {
        onSuccess: () => {
          openModal('ContactSupportSuccessModal');
        },
      }
    );
  }

  return (
    <>
      <ModalBody px="xxl">
        <VendorField
          vendor={selectedPartnerId}
          setVendor={setSelectedPartnerId}
        />
      </ModalBody>

      <ModalFooter position="right" p="xxl" className={classes.footer}>
        <Button
          variant="subtle"
          color="blue_gray.8"
          onClick={() =>
            openModal<ContactSupportModalProps['data']>('ContactSupportModal', {
              onSubmit: onSendSupportEmail,
              title: `Device claiming`,
            })
          }
        >
          Contact support
        </Button>

        <Button type="button" variant="default" onClick={handleClose}>
          Cancel
        </Button>

        <Button type="submit" disabled>
          Claim device
        </Button>
      </ModalFooter>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
