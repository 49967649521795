import { createStyles, LoadingOverlay, Stack, Text } from '@mantine/core';
import React from 'react';

import { ProductImage } from '@portals/framework';
import { LicenseType } from '@portals/types';

import { ReactComponent as LicensePlaceholder } from './LicensePlaceholder.svg';

interface RedeemableLicenseProps {
  license: null | LicenseType;
  isLoading: boolean;
}

export function RedeemableLicense({
  isLoading,
  license,
}: RedeemableLicenseProps) {
  const { classes } = useStyles();

  if (isLoading) {
    return (
      <Stack
        pos="relative"
        justify="center"
        align="center"
        w={404}
        h={228}
        bg="gray.0"
        className={classes.container}
      >
        <LoadingOverlay visible className={classes.loadingOverlay} />
      </Stack>
    );
  } else if (!license) {
    return <LicensePlaceholder />;
  }

  return (
    <Stack
      pos="relative"
      w={404}
      h={228}
      bg="white"
      className={classes.container}
      p="xl"
    >
      <Stack w={80} h={80}>
        <ProductImage src={license.product_image} />
      </Stack>

      <Stack spacing={4}>
        <Text size="lg" color="gray.9" weight={500}>
          {license.product_name}
        </Text>
        <Text size="xs" color="gray.5">
          by {license.partner_display_name}
        </Text>
      </Stack>

      <Text>{license.product_subtitle}</Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    overflow: 'hidden',
  },
  loadingOverlay: {
    background: theme.colors.gray[3],
  },
}));
